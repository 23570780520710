<template>
    <div class="documents-container clearfix">

        <h1>Informes</h1>
        
        <section>
            <div class="box">
                <div class="one my-card table-scroll">
                    <table class="my-datatable">
                        <thead>
                            <tr class="dark-blue">
                                <th>Nombre</th>
                                <th>Estado</th>
                                <th>Fecha de Creación</th>
                                <th>Disponible hasta</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><i class="far fa-file-excel dark-blue" style="margin: 0 10px;"></i> Informe Juan Octubre 2021</td>
                                <td><i class="fas fa-check-circle" style="color:lightseagreen; margin: 0 10px;"></i>Completo</td>
                                <td>12/11/2000</td>
                                <td>12/12/2000</td>
                                <td>
                                    <i title="Eliminar" class="far fa-trash-alt" style="color:coral; margin-right:10px;"></i>
                                    <i title="Descargar" class="fas fa-download dark-blue"></i>
                                    <i title="Editar nombre" class="fas fa-pencil-alt" style="margin-left:10px; color:lightseagreen;"></i>
                                </td>
                            </tr>
                            <tr>
                                <td><i class="far fa-file-pdf dark-blue" style="margin: 0 10px;"></i> Informe 2º Primaria A</td>
                                <td>
                                    <svg  aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-spinner fa-w-16 fa-spin fa-lg"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" class=""></path></svg>
                                    En Proceso</td>
                                <td>01/01/2001</td>
                                <td>01/02/2001</td>
                                <td>
                                    <i title="Eliminar" class="far fa-trash-alt" style="color:coral; margin-right:10px;"></i>
                                    <i title="Descargar" class="fas fa-download dark-blue"></i>
                                    <i title="Editar nombre" class="fas fa-pencil-alt" style="margin-left:10px; color:lightseagreen;"></i>
                                </td>
                            </tr>
                            <tr>
                                <td><i class="far fa-file-pdf dark-blue" style="margin: 0 10px;"></i> Informe Grupo NEE Julio</td>
                                <td><i class="fas fa-check-circle" style="color:lightseagreen; margin: 0 10px;"></i>Completo</td>
                                <td>21/08/2000</td>
                                <td>21/09/2000</td>
                                <td>
                                    <i title="Eliminar" class="far fa-trash-alt" style="color:coral; margin-right:10px;"></i>
                                    <i title="Descargar" class="fas fa-download dark-blue"></i>
                                    <i title="Editar nombre" class="fas fa-pencil-alt" style="margin-left:10px; color:lightseagreen;"></i>
                                </td>
                            </tr>
                            <tr>
                                <td><i class="far fa-file-archive dark-blue" style="margin: 0 10px;"></i> Informe General 2020</td>
                                <td>
                                    <svg  aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-spinner fa-w-16 fa-spin fa-lg"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" class=""></path></svg>
                                    En Proceso</td>
                                <td>15/06/2001</td>
                                <td>15/07/2001</td>
                                <td>
                                    <i title="Eliminar" class="far fa-trash-alt" style="color:coral; margin-right:10px;"></i>
                                    <i title="Descargar" class="fas fa-download dark-blue"></i>
                                    <i title="Editar nombre" class="fas fa-pencil-alt" style="margin-left:10px; color:lightseagreen;"></i>
                                </td>
                            </tr>
                            <tr>
                                <td><i class="far fa-file-archive dark-blue" style="margin: 0 10px;"></i> Informe General 2021</td>
                                <td><i class="fas fa-times-circle" style="color:coral; margin: 0 10px;"></i>Error</td>
                                <td>15/06/2001</td>
                                <td></td>
                                <td>
                                    <i class="far fa-trash-alt" style="color:coral; margin-right:10px;"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="two">
                    <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime quam exercitationem ipsum sapiente est eius? Accusamus facilis velit, laborum pariatur optio laudantium odit assumenda vel placeat neque similique earum modi.
                    </p>
                    <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime quam exercitationem ipsum sapiente est eius? Accusamus facilis velit, laborum pariatur optio laudantium odit assumenda vel placeat neque similique earum modi.
                    </p>              
                </div>
            </div>

        </section>
        


            
        
    </div>
</template>

<script>
export default {
    name: "ReportsList"
}
</script>

<style>
.documents-container{
    margin: 0 20px;
    height: 100%;
    
}

.box {
    /* height: 50vh; */
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-around;

}
.one {
    flex: 1 1 auto;
    width: 70%;
    order:2;
    
}


.two {
    flex: 1 1 300px;
    width: 30%;

    margin: 5px;
    padding: 15px;
    order:1;
}

.three {
    flex: 1 1 250px;
    margin: 5px;
    max-width: 500px;
}

.cursor-pointer{
    cursor: pointer;
}
.card-img-top{
        background-color: #fcfcfc;
        border-top-left-radius: calc(0.428rem - 1px);
    border-top-right-radius: calc(0.428rem - 1px);
        border-style: none;
        flex-shrink: 0;
    width: 100%;
        vertical-align: middle;
}
.my-card{
    border: none;
    margin-bottom: 2rem;
    box-shadow: 0 4px 24px 0 rgba(34,41,47,.1);
    background-color: #fff;
    background-clip: border-box;
    /* border: 1px solid rgba(34,41,47,.125); */
    border-radius: 0.428rem;
    transition: all .3s ease-in-out,background 0s,color 0s, border-color 0s;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
}
.my-card-body{
    padding: 1.5rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
}
.table-scroll{
    overflow-y: auto;
}

.my-datatable {
    width: 100%;
    max-width: 100%;
    margin-bottom: 22px;
    min-width: 500px;
}
.my-datatable {
    background-color: transparent;
}
.my-datatable {
    border-collapse: collapse;
    border-spacing: 0;
}

.my-datatable > thead:first-child > tr:first-child > th, 
.my-datatable > thead:first-child > tr:first-child > td {
    border-top: 0;
}

.my-datatable > thead > tr > th, 
.my-datatable > tbody > tr > th, 
.my-datatable > tfoot > tr > th, 
.my-datatable > thead > tr > td, 
.my-datatable > tbody > tr > td, 
.my-datatable > tfoot > tr > td {
    border: none;
    vertical-align: middle;
}


.my-datatable > thead > tr > th, 
.my-datatable > thead > tr > td, 
.my-datatable > tbody > tr > th, 
.my-datatable > tbody > tr > td, 
.my-datatable > tfoot > tr > th, 
.my-datatable > tfoot > tr > td {
    padding: 8px;
    line-height: 1.6;

    /* ellipsis */
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.my-datatable thead tr:first-child th:nth-child(1) {
    border-top-left-radius: 0.428rem;
    
}
.my-datatable thead tr:first-child th:last-child {
    border-top-right-radius: 0.428rem;
}
.my-datatable tbody tr:first-child td:nth-child(1) {
    border-top-left-radius: 0;
}
.my-datatable tbody tr:first-child td:last-child {
    border-top-right-radius: 0;
}
.my-datatable tbody tr:last-child td:nth-child(1), .dreamers tbody tr:last-child td:nth-child(1) {
    border-bottom-left-radius: 0.428rem;
}
.my-datatable tbody tr:last-child td:last-child {
    border-bottom-right-radius: 0.428rem;
}

.my-datatable > thead > tr:nth-child(1){
    background-color: /* #f3f2f7  */ #e9f5fd !important;
}
.my-datatable > tbody > tr:nth-child(n){
    background-color: white !important;
    
}
.my-datatable > tbody > tr{
    border-bottom: 1px solid whitesmoke;
}
.my-datatable > tbody > tr:last-child{
    border: 0;
}

/* .my-datatable > tbody{
    border: 1px solid #83e7fd;
    border-radius: 10px;
} */


/* SPINNER */

svg:not(:root).svg-inline--fa {
    overflow: visible;
}
.svg-inline--fa.fa-w-16 {
    width: 1em;
}
.svg-inline--fa.fa-lg {
    vertical-align: -0.225em;
}
.fa-spin {
    -webkit-animation: fa-spin 2s linear infinite;
    animation: fa-spin 2s linear infinite;
}
.fa-lg {
    font-size: 14px;
    margin: 0 10px;
    line-height: .75em;
    /* vertical-align: -0.0667em; */
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* Tooltip container */
.my-tooltip {
  position: relative;
  display: inline-block;
/*  border-bottom: 1px dotted black;  If you want dots under the hoverable text */
}

/* Tooltip text */
.my-tooltip .my-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.my-tooltip:hover .my-tooltiptext {
  visibility: visible;
}
</style>